<template>
  <div>
    <div class="air__utils__heading">
      <h5>Roles</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--Botonera  -->
    <div class="row justify-content-center">
      <div class="col-12 text-right">
        <b-button :to="{name:'detail_role', params: {role:'0'}}" pill  variant="primary" >
          <b-icon-person-plus-fill/> Nuevo
        </b-button>
      </div>
    </div>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getCatalogs"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />
    <a-table
      :scroll="{x: 1300}"
      :dataSource="data"
      :columns="columns"
      :loading="loading"
      :row-key="record => record.name"
      :pagination="pagination"
      @change="getCatalogs"
      >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar ${column.dataIndex}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type='search' :style="{ color: filtered ? '#108ee9' : undefined }" />
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <div slot="permissions" slot-scope="data" class="text-justify">
        <template v-if="data.permissions.length">
<!--          <span>Cuenta con {{data.permissions.length}} permisos</span>-->
          <small v-for="permission in data.permissions" :key="permission.name">
            <b-icon icon="key-fill"></b-icon>{{permission.display_name}}
            <small v-if="data.length > 1">,</small>
          </small>
        </template>
        <span v-else> Sin permisos asignados </span>
      </div>
      <div slot="action" slot-scope="data">
        <b-button variant="primary" pill :to="{ name: 'detail_role', params: { role: data.name }}">
          <b-icon icon="arrow-right-circle-fill"></b-icon> Ver Detalle
        </b-button>
      </div>
    </a-table>

  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogBar from '../components/searchBar'

const routes = [
  {
    name: 'home',
    breadcrumbName: 'Inicio',
    bIcon: 'house-fill',
  },
  {
    name: 'admin_roles',
    breadcrumbName: 'Roles',
    aIcon: '',
  },
]
export default {
  name: 'TablesAntdCustomFilter',
  components: {
    DetailCatalogBar,
  },
  data () {
    return {
      fields: ['first_name', 'last_name', 'show_details'],
      tableColumns: [
        { key: 'show_details', label: 'Más', class: 'text-center' },
        { key: 'name', label: 'Nombre', class: 'text-center' },
        { key: 'email', label: 'Email', class: 'text-center' },
        { key: 'details', label: 'Detalles', class: 'text-center' },
        { key: 'actions', label: 'Acciones', class: 'text-center' },
      ],
      items: [
        { isActive: true, age: 40, name: 'Dickerson', email: 'Macdonald' },
        { isActive: false, age: 21, name: 'Larsen', email: 'Shaw' },
        { isActive: false, age: 89, name: 'Geneva', email: 'Wilson' },
        { isActive: true, age: 38, name: 'Jami', email: 'Carney' },
      ],
      routes,
      // Varialbles para la tabla
      data: [],
      searchText: '',
      searchInput: null,
      columns: [
        {
          title: 'Nombre',
          dataIndex: 'display_name',
          key: 'display_name',
          fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) => record.display_name.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Permisos',
          dataIndex: 'role',
          key: 'permissions',
          scopedSlots: {
            customRender: 'permissions',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          key: 'x',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      pagination: {
        current: 1,
        pageSize: 3,
        from: 0,
        to: 0,
        total: 30,
        lastPage: 0,
      },
      loading: false,
      // Varialbles para la tabla
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'display_name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'display_name', text: 'Nombre' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return 'display_name'
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    getCatalogs(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.pagination.current = paginationEvent.current
        this.pagination.pageSize = paginationEvent.pageSize
      }

      this.loading = true
      this.$store.dispatch('jv/get', ['jsonRoles', {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          'filter[display_name]': `${this.searchValue}`,
        },
      }])
        .then(response => {
          this.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.data = objectArray(tableData)
          this.pagination = resolvePagination(jsonApiPagination)
        })
        .catch(errors => {
          console.log(errors)
        })
    },
  },
  mounted() {
    this.getCatalogs()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
